<template>
  <div class="component-menu-top">
    <div class="d-flex flex-row category-menu justify-content-between align-items-center">
      <div class="list-menu d-flex flex-row">
        <div v-for="menu in list" :key="menu.text" class="item-left item-hover">
          <div
            class="menu-item"
            v-if="hasPermission(menu)"
            :class="[moduleIsSelected(menu.url, full_path) ? 'active' : '']"
            @click="nextRouteMenu(menu, menu.url, $event)"
          >
            <router-link
              class="spanLink" :to="{ name: menu.name, params: {} }"
            >{{ menu.text }}</router-link>
          </div>
        </div>
      </div>
      <div class="site-filter d-flex flex-row align-items-center">
        <span>Lọc website</span>
        <div class="item-search content-left-item">
          <el-select
            v-model="value"
            placeholder="-- Chọn --" size="mini"
            class="frame-select-common-2022"
            popper-class="frame-select-popper-common-2022"
            @change="changeOrganization"
            clearable
          >
            <el-option
              v-for="item in options" :key="item.id"
              :label="item.name" :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="d-flex subcategory-menu" v-if="subCategoryLength">
      <div v-for="menu in list" :key="menu.text">
        <div
          v-if="moduleIsSelected(menu.url, full_path, list)"
          class="d-flex flex-row sub-category-menu"
        >

          <div v-if="menu.name == 'report'" class="d-flex flex-row sub-category-menu">
            <div class="item-left item-hover d-flex flex-row">
              <div class="sub-menu-item" v-for="item in ListReport" :key="item.name">

                <el-dropdown>
                <span class="el-dropdown-link">
                  {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                  <div v-if="menu.name == 'report'">

                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="subMenu in item.subMenu" :key="subMenu.name">
                        <div
                          v-if="hasSubPermission(subMenu)"
                          @click.stop="nextRoute(subMenu.name, subMenu.url)"
                          :class="[full_path == subMenu.url ? 'sub-menu-active' : '']"
                          style="font-size: 12px;"
                        >
                          {{ reName(subMenu.text) }}
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </div>
                </el-dropdown>
              </div>
            </div>
          </div>

          <div v-else class="d-flex flex-row sub-category-menu">
            <div
              v-for="subMenu in menu.subcategory" :key="subMenu.name"
              class="item-left item-hover"
            >
              <div
                v-if="hasSubPermission(subMenu)"
                class="sub-menu-item"
                @click.stop="nextRoute(subMenu.name, subMenu.url)"
                :class="[full_path == subMenu.url ? 'sub-menu-active' : '']"
              >
                 <div v-if="subMenu.name == 'pack-wait-confirm'">
                   <span size="small">{{ subMenu.text }}</span>
                  <span
                    style="background: #F09920; padding: 3px 5px; margin-left: 5px; border-radius: 5px; color: #fff; font-size: 13px"
                  >{{ totalStatusPackWait | vnd2 }}</span>
                </div>
                <div v-else-if="subMenu.name == 'pack-refuns'">
                  <span size="small">{{ subMenu.text }}</span>
                  <span
                    style="background: #1771C1; padding: 3px 5px; margin-left: 5px; border-radius: 5px; color: #fff; font-size: 13px"
                  >{{ totalStatusPackRefund | vnd2 }}</span>
                </div>
                <div v-else-if="subMenu.name == 'pack-list'">
                  <span size="small">{{ subMenu.text }}</span>
                  <span
                    style="background: #45B4CE; padding: 3px 5px; margin-left: 5px; border-radius: 5px; color: #fff; font-size: 13px"
                  >{{ totalPackageAll | vnd2 }}</span>
                </div>
<!--                <span v-else>{{ reName(subMenu.text) }}</span>-->
                <router-link
                  v-else
                  class="spanColor"
                  :class="[full_path == subMenu.url ? 'sub-menu-active' : '']"
                  :to="{ name: subMenu.name }"
                >{{ reName(subMenu.text) }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import store from "@/store";

export default {
  name: "LayoutMenuLeft",

  data() {
    return {
      showText: true,
      showTextSlow: true,
      totalStatusPackWait: 0,
      totalStatusPackRefund: 0,
      totalPackageAll: 0,
      value: "",
      name: "action",
      topmenuitem: -1,
      options: [],
      list: [
        // Khách hàng
        {
          text: "Khách hàng",
          url: "/customer",
          name: "customer",
          rootPermission: [
            "customer.index"
          ],
          subcategory: [
            {
              text: "Thêm khách hàng mới",
              url: "/customer/customer-lists/create",
              name: "customer-lists-create",
              rootPermission: "customer.add",
              permission: [],
            },
            {
              text: "Danh sách khách hàng",
              url: "/customer/customer-lists",
              name: "customer-lists",
              rootPermission: "customer.index",
              permission: [],
            },
            // {
            //   text: "Chờ gán chăm sóc",
            //   url: "/customer/customer-lists/wait-for-assignment-care",
            //   name: "customer-wait-for-assignment-care",
            //   rootPermission: "customer.index",
            //   permission: [],
            // },
            // {
            //   text: "Chờ gán tư vấn",
            //   url: "/customer/customer-lists/wait-for-assignment-counselor",
            //   name: "customer-wait-for-assignment-counselor",
            //   rootPermission: "customer.index",
            //   permission: [],
            // },
            {
              text: "Khách hàng VIP",
              url: "/customer/customer-levels",
              name: "customer-levels",
              rootPermission: "customer.index",
              permission: [],
            },
            {
              text: "Tiềm năng",
              url: "/customer/customer-potentials",
              name: "customer-potentials",
              rootPermission: "customer.index",
              permission: [],
            },
            {
              text: "Thay đổi nhân viên hỗ trợ",
              url: "/customer/change-staff-requests",
              name: "change-staff-requests",
              rootPermission: "customer.change-staff",
              permission: [],
            },
            {
              text: "Lịch sử xóa khách hàng",
              url: "/customer/with-trashed",
              name: "customer-with-trashed",
              rootPermission: "customer.with-trashed",
              permission: [],
            },
          ],
        },
        // Đơn hàng
        {
          icon: "Order",
          text: "Đơn hàng",
          name: "order-list",
          url: "/order",
          rootPermission: [
            "order.index",
            "order.wait-assign-consulting",
            "order.wait-assign-care",
          ],
          subcategory: [],
        },
        // kiện hàng
        {
          icon: "Pack",
          text: "Kiện hàng",
          name: "pack-list",
          url: "/pack",
          rootPermission: [
            "pack.index",
          ],
          subcategory: [
            {
              text: "Tất cả",
              name: "pack-list",
              url: "/pack/list",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Thêm mới",
              name: "pack-create",
              url: "/pack/create",
              rootPermission: "pack.add",
              permission: [],
            },
            {
              text: "Danh sách yêu cầu hủy kiện",
              name: "pack-cancel",
              url: "/pack/cancel",
              rootPermission: "pack.update-for-tbp",
              permission: [],
            },
            {
              text: "Trả hàng",
              name: "pack-receive-goods",
              url: "/pack/receive-goods",
              rootPermission: "pack.receive-goods",
              permission: [],
            },
            {
              text: "Thống kê phiếu in",
              name: "pack-statistical-print",
              url: "/pack/statistical-print",
              rootPermission: "pack.statistical-print",
              permission: [],
            },
            {
              text: "Chờ xác nhận",
              name: "pack-wait-confirm",
              url: "/pack/wait-confirm",
              rootPermission: "pack.wait-confirm",
              permission: [],
            },
            {
              text: "Kiểm hàng",
              name: "pack-refuns",
              url: "/pack/refuns",
              rootPermission: "pack.check-refund",
              permission: [],
            }
          ],
        },
        // Đặt hàng
        {
          icon: "Pack",
          text: "Đặt hàng",
          name: "place-order-cost",
          url: "/place-order-cost",
          rootPermission: [
            // "place_order.index",
            // "place_order.assign_support_list",
            // "place_order.report_list",
            "actual_amount.index"
          ],
          subcategory: [
            // {
            //   text: "Đơn hàng của tôi",
            //   name: "place-order",
            //   url: "/place-order",
            //   rootPermission: "place_order.index",
            //   permission: [],
            // },
            // {
            //   text: "Đơn hàng hỗ trợ",
            //   name: "place-order-support",
            //   url: "/place-order-support",
            //   rootPermission: "place_order.assign_support_list",
            //   permission: [],
            // },
            // {
            //   text: "Báo cáo đặt hàng",
            //   name: "place-order-buyder",
            //   url: "/place-order/buyder",
            //   rootPermission: "place_order.report_list",
            //   permission: [],
            // },
            // {
            //   text: "Thanh toán",
            //   name: "place-order-payment",
            //   url: "/place-order-payment",
            //   rootPermission: "place_order.payment",
            //   permission: [],
            // },
            {
              text: "Cập nhật tổng tiền thanh toán",
              name: "place-order-cost",
              url: "/place-order-cost",
              rootPermission: "actual_amount.index",
              permission: [],
            },

          ],
        },
        //Hoàn tiền
        {
          icon: "Refund",
          text: "Hoàn tiền",
          name: "refund",
          url: "/refund",
          rootPermission: [
            "refund.index"
          ],
          subcategory: [],
        },
        // Giao hàng
        {
          icon: "Delivery",
          text: "Giao hàng",
          name: "delivery-list",
          url: "/delivery-list",
          rootPermission: [
            "delivery.index",
          ],
          subcategory: [],
        },
        // Khiếu nại
        {
          icon: "Complain",
          text: "Khiếu nại",
          name: "complain-list",
          url: "/complain-list",
          rootPermission: [
            "complain.index",
          ],
          subcategory: [],
        },
        // Nộp phạt
        // {
        //   text: "Nộp phạt",
        //   url: "/fines",
        //   name: "fines",
        //   rootPermission: [
        //     "fine.index"
        //   ],
        //   subcategory: [],
        // },
        // Kế toán
        {
          icon: "Accountant",
          text: "Kế toán",
          name: "accountant",
          url: "/accountant",
          rootPermission: [
            "fund.index",
            "fund.history-vn",
            "fund.recharge-tq",
            "fund.detail-real-ncc",
            "fund.history-tq",
            "fund.index-withdraw",
            "fine.index",
            "advanceMoney.index",
          ],
          subcategory: [
            {
              text: "Quỹ tồn",
              name: "fund-list",
              url: "/accountant/fund-list",
              rootPermission: "fund.index",
              permission: [],
            },
            {
              text: "Thu chi tiền Việt",
              name: "fund-transaction-list-vn",
              url: "/accountant/fund-transaction-list-vn",
              rootPermission: "fund.history-vn",
              permission: [],
            },
            {
              text: "Loại thanh toán",
              url: "/accountant/fund-type-pay-list",
              name: "fund-type-pay-list",
              rootPermission: "fund.type_payment",
              permission: [],
            },
            {
              text: "Nạp tiền NH TQ",
              name: "fund-recharge",
              url: "/accountant/fund-recharge",
              rootPermission: "fund.recharge-tq",
              permission: [],
            },
            {
              text: "Thanh toán thực tế NCC",
              name: "fund-ncc",
              url: "/accountant/fund-ncc",
              rootPermission: "fund.detail-real-ncc",
              permission: [],
            },
            {
              text: "Thu chi tiền TQ",
              name: "fund-transaction-list-cn",
              url: "/accountant/fund-transaction-list-cn",
              rootPermission: "fund.history-tq",
              permission: [],
            },
            {
              text: "Rút tiền",
              name: "fund-withdrawal",
              url: "/accountant/fund-withdrawal",
              rootPermission: "fund.index-withdraw",
              permission: [],
            },
            {
              text: "Ứng tiền",
              name: "accountant-advance-money",
              url: "/accountant/advance-money",
              rootPermission: "advanceMoney.index",
              permission: [],
            },
            {
              text: "Nộp phạt",
              url: "/accountant/fines-lists",
              name: "fines-lists",
              rootPermission:
                "fine.index"
              ,
              permission: [],
            },
            {
              text: "Đã xóa",
              url: "/accountant/fund-transaction-deleted",
              name: "fund-transaction-deleted",
              rootPermission:
                "fund.ls-vn-view-history-delete"
              ,
              permission: [],
            },
            {
              text: "Công nợ KH",
              url: "/accountant/fund-debt",
              name: "fund-debt",
              rootPermission: "fund.fund-debt",
              permission: [],
            },
            {
              text: "BC thu chi",
              url: "/accountant/revenue-expenditure",
              name: "revenue-expenditure",
              rootPermission: "fund.revenue-expenditure",
              permission: [],
            },
            {
              text: "BC đổi RMB",
              url: "/accountant/conversion-rmb",
              name: "conversion-rmb",
              rootPermission: "fund.rmb",
              permission: [],
            },
            {
              text: "Cài đặt loại quỹ",
              url: "/accountant/set-fund-type",
              name: "set-fund-type",
              rootPermission: "fund.setting-type",
              permission: [],
            },
          ],
        },
        // Báo cáo
        {
          icon: "Report",
          text: "Báo cáo",
          name: "report",
          url: "/report",
          rootPermission: [
            "report.consulting-manage-customer-potential",
            "report.care-customer-active",
            "report.consulting-management-customer-by-orders",
            "report.order-done",
            "report.consulting-customer-pack-to-vn",
            "report.quote-activity",
            "report.order-staff-activity",
            "report.complain-staff-activity",
            "report.performance-sale-and-care-consulting",
            "report.purchase-channel",
            "report.sale",
            "report.transaction",
          ],
          subcategory: [
            {
              text: "Tư vấn KH tiềm năng",
              name: "potential-customer-management",
              url: "/report-potential-customer-management",
              rootPermission: "report.consulting-manage-customer-potential",
              permission: [],
            },
            {
              text: "Chăm sóc KH đang sử dụng",
              name: "report-customer-active",
              url: "/report-customer-active",
              rootPermission: "report.care-customer-active",
              permission: [],
            },
            {
              text: "Tư vấn KH theo đơn",
              name: "report-customer-order",
              url: "/report-customer-order",
              rootPermission: "report.consulting-management-customer-by-orders",
              permission: [],
            },
            {
              text: "Đơn hoàn thành",
              name: "report-order-complete",
              url: "/report-order-complete",
              rootPermission: "report.order-done",
              permission: [],
            },
            {
              text: "Tư vấn KH kiện hàng về VN",
              name: "report-customer-pack-vn",
              url: "/report-customer-pack-vn",
              rootPermission: "report.consulting-customer-pack-to-vn",
              permission: [],
            },
            {
              text: "Hoạt động báo giá",
              name: "report-staff-quotation",
              url: "/report-staff-quotation",
              rootPermission: "report.quote-activity",
              permission: [],
            },
            {
              text: "Hoạt động đặt hàng",
              name: "report-staff-order",
              url: "/report-staff-order",
              rootPermission: "report.order-staff-activity",
              permission: [],
            },
            {
              text: "Hoạt động khiếu nại",
              name: "report-staff-complain",
              url: "/report-staff-complain",
              rootPermission: "report.complain-staff-activity",
              permission: [],
            },
            {
              text: "Báo cáo doanh số",
              name: "report-sale",
              url: "/report-sale",
              rootPermission: "report.sale",
              permission: [],
            },
            {
              text: "Báo cáo giao dịch",
              name: "report-transaction",
              url: "/report-transaction",
              rootPermission: "report.transaction",
              permission: [],
            },
            {
              text: "Hiệu suất nhân viên",
              name: "report-staff-care-counselor",
              url: "/report-staff-care-counselor",
              rootPermission: "report.performance-sale-and-care-consulting",
              permission: [],
            },
            {
              text: "Đánh giá",
              name: "report-rate",
              url: "/report-rate",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Báo cáo kênh mua hàng",
              name: "report-purchase-channel",
              url: "/report-purchase-channel",
              rootPermission: "report.purchase-channel",
              permission: [],
            },
            {
              text: "Báo cáo phí vận chuyển",
              name: "report-transport-fee",
              url: "/report-transport-fee",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Báo cáo tuần",
              name: "report-order-customer-week",
              url: "/report-order-customer-week",
              rootPermission: "report.sale",
              permission: [],
            }
          ],
        },
        // Tài khoản
        {
          icon: "Administrator",
          text: "Tài khoản",
          name: "administrator",
          url: "/administrator",
          rootPermission: [
            "staff.index",
            "department.index",
            "role.index",
            "staff.list-group",
            "systemadmin@thuongdo.com"
          ],
          subcategory: [
            {
              text: "Danh sách nhân viên",
              name: "user-management",
              url: "/administrator/user-management",
              rootPermission: "staff.index",
              permission: [
                // "create-user",
                // "update-user",
                // "delete-user",
                // "list-user",
              ],
            },
            {
              text: "Danh sách phòng ban",
              url: "/administrator/department-management",
              name: "department-management",
              rootPermission: "department.index",
              emailRole: "",
              permission: [
                // "create-department",
                // "update-department",
                // "delete-department",
                // "list-department",
              ],
            },
            {
              text: "Danh sách công ty",
              url: "/administrator/organization-management",
              name: "organization-management",
              role: "System Administrator",
              emailRole: "systemadmin@thuongdo.com",
              rootPermission: "systemadmin@thuongdo.com",
              permission: [
                // "create-organization",
                // "update-organization",
                // "delete-organization",
                // "list-organization",
              ],
            },
            {
              text: "Vai trò",
              url: "/administrator/role-management",
              name: "role-management",
              role: "System Administrator",
              rootPermission: "role.index",
              permission: [
                // "create-role",
                // "update-role",
                // "delete-role",
                // "list-role",
              ],
            },
            {
              text: "Danh sách nhóm kinh doanh",
              url: "/administrator/group-management",
              name: "group-management",
              rootPermission: "order.index",
              emailRole: "",
              permission: [
                // "create-department",
                // "update-department",
                // "delete-department",
                // "list-department",
              ],
            },
          ],
        },
        // Cài đặt chung
        {
          icon: "Setting",
          text: "Cài đặt",
          name: "setting",
          url: "/setting",
          rootPermission: [],
          subcategory: [
            {
              text: "Thông tin tài khoản",
              name: "profile-user",
              url: "/setting/profile",
              rootPermission: "",
              permission: [],
            },
            // {
            //   text: "Danh sách loại thanh toán",
            //   url: "/setting/fund-type-pay-list",
            //   name: "fund-type-pay-list",
            //   rootPermission: "setting.index",
            //   permission: [],
            // },
            // quyền ds các bảng phí
            // xem: setting.show-config
            // sửa: setting.edit-config
            {
              text: "Danh sách các bảng phí",
              url: "/setting/changes-in-value",
              name: "changes-in-value",
              rootPermission: "setting.show-config",
              permission: [],
            },
            {
              text: "Xóa dữ liệu",
              url: "/setting/trash-customer",
              name: "trash-customer",
              rootPermission: "setting.delete",
              permission: [],
            },
            {
              text: "Phản hồi khiếu nại",
              url: "/setting/complain",
              name: "setting-complain",
              rootPermission: "setting.respond-complain",
              permission: [],
            },
            {
              text: "Địa chỉ nhận hàng mặc định",
              url: "/setting/config-address-receivers",
              name: "config-address-receivers",
              rootPermission: "setting.default-shipping-address",
              permission: [],
            },
            {
              text: "Lý do hủy đơn",
              url: "/setting/config-reason-cancel-order",
              name: "config-reason-cancel-order",
              rootPermission: "setting.reason-cancel",
              permission: [],
            },
            {
              text: "Phí vận chuyển ước tính",
              url: "/setting/config-shipping-estimated-fee",
              name: "config-shipping-estimated-fee",
              rootPermission: "setting.estimated-shipping-fee",
              permission: [],
            },
            {
              text: "Ghi chú đơn hàng",
              url: "/setting/note-order",
              name: "config-note-order",
              rootPermission: "setting.note-order",
              permission: [],
            },
          ],
        },
      ],
      permissionInfo: [],

      // Menu Báo cáo mới

      ListReport: [
        {
          name: "Khách hàng",
          per: false,
          subMenu: [
            {
              text: "Tư vấn KH tiềm năng",
              name: "potential-customer-management",
              url: "/report-potential-customer-management",
              rootPermission: "report.consulting-manage-customer-potential",
              permission: [],
            },
            {
              text: "Chăm sóc KH đang sử dụng",
              name: "report-customer-active",
              url: "/report-customer-active",
              rootPermission: "report.care-customer-active",
              permission: [],
            },
            {
              text: "Tư vấn KH theo đơn",
              name: "report-customer-order",
              url: "/report-customer-order",
              rootPermission: "report.consulting-management-customer-by-orders",
              permission: [],
            },
            {
              text: "Tư vấn KH kiện hàng về VN",
              name: "report-customer-pack-vn",
              url: "/report-customer-pack-vn",
              rootPermission: "report.consulting-customer-pack-to-vn",
              permission: [],
            },
            {
              text: "Báo cáo tuần",
              name: "report-order-customer-week",
              url: "/report-order-customer-week",
              rootPermission: "report.sale",
              permission: [],
            },
            {
              text: "Báo cáo đối soát marketing",
              name: "report-control-marketing",
              url: "/report-control-marketing",
              rootPermission: "report.sale",
              permission: [],
            },
            {
              text: "Báo cáo data phòng",
              name: "report-closing-rate",
              url: "/report-closing-rate",
              rootPermission: "report.sale",
              permission: [],
            },
            {
              text: "Báo cáo tỉnh thành phố",
              name: "report-province",
              url: "/report-province",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Báo cáo kênh lên đơn hàng",
              name: "report-channel-to-order",
              url: "/report-channel-to-order",
              rootPermission: "",
              permission: [],
            },
          ]
        },
        {
          name: "Đơn hàng",
          per: false,
          subMenu: [
            {
              text: "Đơn hoàn thành",
              name: "report-order-complete",
              url: "/report-order-complete",
              rootPermission: "report.order-done",
              permission: [],
            },
          ]
        },
        {
          name: "Vận chuyển",
          per: false,
          subMenu: [
            {
              text: "Báo cáo phí vận chuyển",
              name: "report-transport-fee",
              url: "/report-transport-fee",
              rootPermission: "",
              permission: [],
            },
          ],
        },
        {
          name: "Đặt hàng",
          per: false,
          subMenu: [
            {
              text: "Hoạt động báo giá",
              name: "report-staff-quotation",
              url: "/report-staff-quotation",
              rootPermission: "report.quote-activity",
              permission: [],
            },
            {
              text: "Hoạt động đặt hàng",
              name: "report-staff-order",
              url: "/report-staff-order",
              rootPermission: "report.order-staff-activity",
              permission: [],
            },
            {
              text: "Hoạt động khiếu nại",
              name: "report-staff-complain",
              url: "/report-staff-complain",
              rootPermission: "report.complain-staff-activity",
              permission: [],
            },
            {
              text: "Báo cáo doanh số",
              name: "report-sale",
              url: "/report-sale",
              rootPermission: "report.sale",
              permission: [],
            },
            {
              text: "Báo cáo giao dịch",
              name: "report-transaction",
              url: "/report-transaction",
              rootPermission: "report.transaction",
              permission: [],
            }
          ],
        },
        {
          name: "Nhân viên",
          per: false,
          subMenu: [
            {
              text: "Báo cáo phí vận chuyển",
              name: "report-transport-fee",
              url: "/report-transport-fee",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Hiệu suất nhân viên",
              name: "report-staff-care-counselor",
              url: "/report-staff-care-counselor",
              rootPermission: "report.performance-sale-and-care-consulting",
              permission: [],
            },
            {
              text: "Đánh giá",
              name: "report-rate",
              url: "/report-rate",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Doanh số theo nhân viên tư vấn",
              name: "report-customer-order-sale-staff",
              url: "/report-customer-order-sale-staff",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Báo cáo data và tỷ lệ chốt nhân viên",
              name: "report-closing-rate-staff",
              url: "/report-closing-rate-staff",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Doanh số theo nhân viên kinh doanh",
              name: "report-sale-staff",
              url: "/report-sale-staff",
              rootPermission: "report.sale-staff",
              permission: [],
            },
            {
              text: "Hiệu suất chốt đơn",
              name: "report-performance-sale-order",
              url: "/report-performance-sale-order",
              rootPermission: "report.performance-sale-and-care-consulting",
              permission: [],
            },
          ],
        },
        {
          name: 'Tìm kiếm',
          per: false,
          subMenu: [
            {
              text: "Báo cáo thống kê qua form",
              name: "report-products-pipeline-form",
              url: "/report-products-pipeline-form",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Báo cáo kết quả tìm kiếm",
              name: "report-products-pipeline-search",
              url: "/report-products-pipeline-search",
              rootPermission: "",
              permission: [],
            },
            {
              text: "Báo cáo thống kê qua từ khoá khách hàng",
              name: "report-products-pipeline-customer",
              url: "/report-products-pipeline-customer",
              rootPermission: "",
              permission: [],
            },
          ],
        },
      ],

      // Check Menu có cái category con thì mới cho hiện thị cái Sub
      subCategoryLength: 0,
      staff: null
    };
  },

  async mounted() {
    this.getListOrganization();
    this.value = this.organization_id;

    // Kiểm tra nếu là nhân viên hiện text là đơn hàng của tôi còn là trưởng phòng hiện text là tất cả
    this.list.forEach(menu => {
      if (menu.name == 'place-order') {
        if (this.accountInfo.roles && this.accountInfo.roles.length && this.accountInfo.roles[0].name.includes('nhan-vien-dat-hang')) {
          menu.subcategory[0].text = 'Đơn hàng của tôi';
        } else {
          menu.subcategory[0].text = 'Tất cả';
        }
      }
    })
    await this.fetchMe();
    await this.getStatusPack();
    this.getCountPackge();
  },

  computed: {
    ...mapGetters(["path", "full_path", "customerCareCount", "customerCounselorCount", "organization_id"]),
    ...mapGetters("account", ["accountInfo"]),
  },

  methods: {
    reName(text) {
      if (text == 'Chờ gán chăm sóc') {
        return 'Chờ gán chăm sóc (' + this.customerCareCount + ')';
      }
      if (text == 'Chờ gán tư vấn') {
        return 'Chờ gán tư vấn (' + this.customerCounselorCount + ')';
      }
      return text;
    },

    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        if (undefined !== name) {
          this.$router.push({name: name});
        }
      }
    },

    nextRouteMenu(menu, full_path, event) {
      if (menu.rootPermission.length === 0) {
        this.$router.push({name: menu.name});
        return;
      }
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      let listRoute = [];
      menu.subcategory.forEach((element) => {
        if (permissions.includes(element.rootPermission)) {
          listRoute.push(element.name);
        }
      });

      if (event.ctrlKey) {
        event.preventDefault();
        const url = this.$router.resolve({
          name: menu.name,
          params: {}
        }).href;
        window.open(url, '_blank');
        return;
      }

      if (this.full_path !== full_path) {
        if (undefined !== name) {
          // this.$router.push({ name: listRoute[0] });
          this.$router.push({name: menu.name});
        }
      }
    },

    moduleIsSelected(modules, path) {
      if (undefined !== path && path.includes(modules)) {
        this.list.forEach((i) => {
          if (modules == i.url) {
            this.subCategoryLength = i.subcategory.length;
          }
        })
        return true;
      }
      return false;
    },

    getListOrganization() {
      axios.get("organization/list").then((response) => {
        switch (response.code) {
          case 200:
            this.options = response.data;
            store.dispatch("setListOrg", this.options);
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    getPermissionInfo(account) {
      let modules = [];
      account.permissions.forEach((permission) => {
        modules.push(permission.module);
      });
      modules = modules.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name
          )
      );
      this.permissionInfo = modules;
      modules.forEach((module, index) => {
        this.permissionInfo[index].permissions = [];
        account.permissions.forEach((permission) => {
          if (permission.module.id === module.id) {
            this.permissionInfo[index].permissions.push(permission.name);
          }
        });
      });
    },

    hasPermission(menu) {
      if (menu.rootPermission.length == 0) {
        return true;
      }
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      if (this.accountInfo.email == "systemadmin@thuongdo.com") {
        permissions.push(this.accountInfo.email)
      }

      return permissions.some((r) => menu.rootPermission.includes(r));
    },

    hasSubPermission(subMenu) {
      //
      if (subMenu.rootPermission === "") {
        return true;
      }
      let permissions = [];
      if (
        undefined !== this.accountInfo.permissions &&
        this.accountInfo.permissions.length > 0
      ) {
        this.accountInfo.permissions.forEach((e) => {
          permissions.push(e.name);
        });
      }
      if (this.accountInfo.email == "systemadmin@thuongdo.com") {
        permissions.push(this.accountInfo.email)
      }
      //
      return permissions.includes(subMenu.rootPermission);
    },
    changeOrganization(value) {
      this.value = value;
      store.dispatch("setOrganization", value);
    },
    getStatusPack() {
      this.loading = true;
      let params = {}
      if(!this.hasPermissionApi('pack.all-pack.wait-confirm')) {
        params.staff_order_id = this.staff.id
      }
      axios.get(process.env.VUE_APP_WARE_HOUSE_URL + "/pack/get-status-pack-wait-and-refund", {params: params}).then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.totalStatusPackWait = response.data.pack_wait;
            this.totalStatusPackRefund = response.data.pack_refund;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !!",
            });
            break;
        }
      });
    },

    getCountPackge() {
      axios.get("package/count-package").then((response) => {
        switch (response.code) {
          case 200:
            this.totalPackageAll = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    hasPermissionApi(permission) {
      let permissions = [];
      this.staff?.permissions.forEach((e) => {
        permissions.push(e.name);
      });
      return permissions.includes(permission);
    },
    async fetchMe() {
      await axios.get("user/me").then((response) => {
        if(response.code == 200) {
          this.staff = response.data
        }
      })
    }
  },
};
</script>

<style lang="css" scoped>
  .component-menu-top .category-menu {
    background-color: #172259;
    height: 40px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
  }

  .component-menu-top .subcategory-menu {
    background-color: #fff;
    border-bottom: 1px solid #bdd6ee;
    cursor: pointer;
    padding: 2px 10px;
    text-align: center;
  }

  .component-menu-top .category-menu .menu-item {
    padding: 10px 10px 14px 10px;
    color: #fff;
    font-size: 14px;
  }

  .component-menu-top .subcategory-menu .sub-menu-item {
    padding: 10px 10px 14px 10px;
    color: #767676;
    font-size: 14px;
    font-weight: 400;
  }

  .active {
    background: #fff !important;
    color: #000728 !important;
    line-height: 20px;
    font-weight: 400;
  }

  .sub-menu-active {
    color: #0688ee !important;
  }

  .site-filter span {
    color: #fff;
    padding-right: 10px;
    font-weight: 400;
    font-size: 12px;
  }

  .spanLink {
    color: #fff;
    text-decoration: none
  }

  .active .spanLink {
    background: #fff !important;
    color: #000728 !important;
    line-height: 20px;
    font-weight: 400;
  }
  .spanColor {
    color:#767676;
  }
</style>
